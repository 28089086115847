/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components), {Text} = _components;
  if (!Text) _missingMdxReference("Text", true);
  return React.createElement(React.Fragment, null, React.createElement(Text, {
    children: `Hi.`,
    sx: {
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }
  }), "\n", React.createElement(_components.p, null, "Empty for now."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
